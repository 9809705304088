<template>
  <div>
    <en-table-layout :tableData="pageData.data" @selection-change="orderListSelectionChange" :row-key="getRowKeys"
      ref="enTableLayout">
      <template slot="toolbar">
        <div class="button-back">
            <el-button @click="() => {
      $router.go(-1);
    }
      " size="small" class="back_btn">
              <img src="@/assets/back.png" alt="" />
            </el-button>
        </div>
        <el-form-item label="单据生成日期">
          <el-date-picker style="width: 250px" v-model="order_time_range" type="daterange" align="center" size="medium"
            :editable="false" unlink-panels range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"
            @change="handleDateChange"></el-date-picker>
        </el-form-item>
        <el-form-item label="单据类型" class="col-auto">
          <el-select style="width: 120px" size="medium" v-model="params.bill_type" placeholder="请选择" clearable>
            <el-option label="全部" value=""></el-option>
            <el-option label="销售开卡单" :value="3"></el-option>
            <el-option label="销售退卡单" :value="5"></el-option>
            <el-option label="充值销售单" :value="4"></el-option>
            <el-option label="充值退款单" :value="6"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="应用类型" class="col-auto">
          <el-select style="width: 140px" v-model="params.app_type" size="medium" clearable>
            <el-option v-for="(item, index) in filterdouble" :key="index" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="单据来源" class="col-auto">
          <el-select style="width: 120px" size="medium" v-model="params.bill_source" placeholder="请选择" clearable>
            <el-option label="全部" value></el-option>
            <el-option label="企业后台" value="1"></el-option>
            <el-option label="移动端前台" value="2"></el-option>
          </el-select>
        </el-form-item>
      </template>
      <template slot="toolbar_btn">
        <el-form-item>
          <el-input size="medium" v-model.trim="params.keyWord" placeholder="请输入关键字进行搜索" clearable>
            <el-select slot="prepend" size="medium" v-model="key_word" placeholder="请选择类型" style="width: 130px">
              <el-option label="单据编号" value="bill_no"></el-option>
              <el-option label="销售人员名称" value="salesman_name"></el-option>
              <el-option label="联系人名称" value="contacts_person"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="searchEvent" type="primary" size="small">搜索</el-button>
          <el-button @click="submitImport(0)" :loading="importLoading" size="small" type="primary">导出全部</el-button>
          <el-button @click="submitImport(1)" :loading="importLoading1" size="small" type="primary">导出勾选项</el-button>
        </el-form-item>
        <br />
        <div style="width: 100%;">
          <p>客户名称：{{ formData.customer_name }}</p>
          <div class="col-12 userData">
            <el-row type="flex" class="user-statistics">
              <el-col>
                <p>累计应收金额:{{ formData.account_accumulated_receivable ? formData.account_accumulated_receivable : 0}}</p>
              </el-col>
              <el-col>
                <p>累计已收金额:{{ formData.account_accumulated_received ? formData.account_accumulated_received : 0}}</p>
              </el-col>
              <el-col>
                <p>累计应退金额:{{ formData.account_accumulated_refunds ? formData.account_accumulated_refunds : 0}}</p>
              </el-col>
              <el-col>
                <p>累计已退金额:{{ formData.account_accumulated_refunded ? formData.account_accumulated_refunded : 0}}</p>
              </el-col>
            </el-row>
          </div>
        </div>
      </template>
      <template slot="table-columns">
        <el-table-column type="selection" :reserve-selection="true" fixed="left" />
        <el-table-column align="center" show-overflow-tooltip prop="bill_no" label="单据编号" width="160" fixed="left" />
        <el-table-column align="center" prop="bill_type" label="单据类型" width="160" show-overflow-tooltip fixed="left">
          <template slot-scope="{ row }">{{ bill_type_filters(row.bill_type) }}</template>
        </el-table-column>
        <el-table-column align="center" prop="bill_source" label="单据来源" width="100" show-overflow-tooltip>
          <template slot-scope="{ row }">{{ row.bill_source == 1 ? '企业后台' : '移动端前台' }}</template>
        </el-table-column>
        <el-table-column align="center" prop="account_receivable" label="应收金额" width="100" show-overflow-tooltip />
        <el-table-column align="center" prop="account_received" label="已收金额" width="100" show-overflow-tooltip />
        <el-table-column align="center" prop="account_refunds" label="应退金额" width="100" show-overflow-tooltip />
        <el-table-column align="center" prop="account_refunded" label="已退金额" width="150" show-overflow-tooltip />
        <el-table-column align="center" prop="app_type" label="应用类型" width="140" show-overflow-tooltip>
          <template slot-scope="{ row }">
            {{ getAppTypeName(row.app_type) }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="contacts_person" label="联系人名称" width="120" show-overflow-tooltip />
        <el-table-column align="center" prop="salesman_name" label="销售人员" width="100" show-overflow-tooltip />
        <el-table-column align="center" prop="bill_create_time" label="单据生成日期" width="150" show-overflow-tooltip>
          <template slot-scope="{ row }">{{ row.bill_create_time | unixToDate }}</template>
        </el-table-column>
      </template>
      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="pageData.page_no" :page-size="pageData.page_size" :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout" background :total="pageData.data_total"></el-pagination>
    </en-table-layout>
  </div>
</template>
<script>
import * as API_order from "@/api/order";
import { handleDownload } from "@/utils";
import { Foundation } from "@/../ui-utils";
import EnTableLayout from "../../../../ui-components/TableLayout/src/main";
import mixins from "@/views/tools/sale-card-open/modules/saleListMixins";
import { getCustomerAccountVerificationDetailList, getCustomerAccountVerificationDetailListExport, getCustomerAccountVerificationDetailListExportByIds } from "@/api/settingCustomer";
export default {
  name: "writeOffOpenCardDetail",
  components: {
    EnTableLayout,
  },
  computed: {
    filterdouble () {
      if (!this.double_choice_mall_open) {
        this.cardTypes.splice(2, 1)
      }
      return this.cardTypes;
    }
  },
  mixins: [mixins],
  data () {
    return {
      double_choice_mall_open: true,//是否开启双选商城
      // 导出loading状态
      importLoading: false,
      importLoading1: false,
      order_time_range: [],
      key_word: 'bill_no',
      params: {
        page_no: 1,
        page_size: 20,
        customer_id: '',
        keyWord: "",
        bill_type: "",
        app_type: "",
        bill_source: "",
      },
      // 列表选中
      tempList: [],
      // 列表分页数据
      pageData: { data: [] },
      formData: {},
      exportHeader: [
        "单据编号",
        "单据类型",
        "单据来源",
        "应收金额",
        "已收金额",
        "应退金额",
        "已退金额",
        "应用类型",
        "联系人名称",
        "销售人员",
        "单据生成日期"
      ],
      exportKeys: [
        "bill_no",
        "bill_type",
        "bill_source",
        "account_receivable",
        "account_received",
        "account_refunds",
        "account_refunded",
        "app_type",
        "contacts_person",
        "salesman_name",
        "bill_create_time"
      ],
    }
  },
  mounted () {
    this.params.customer_id = this.$route.params.id;
    if(this.$route.query.order_time_range){
      this.order_time_range=JSON.parse(this.$route.query.order_time_range)
    }
    API_order.getShopExtAuth().then(resStatus => {//是否开启双选商城
      if (resStatus) {
        this.double_choice_mall_open =
          resStatus.double_choice_mall_open === "OPEN";
      }
    });
    this.GET_GoodsAuditList()
  },
  methods: {
    bill_type_filters (type) {
      const maps = {
        3: '销售开卡单',
        4: '充值销售单',
        5: '销售退卡单',
        6: '充值退款单'
      }
      return maps[type]
    },
    // 选中数据
    orderListSelectionChange (list) {
      this.tempList = list;
    },
    //格式化时间控件 部门审批时间
    handleDateChange () {
      if (
        this.order_time_range &&
        this.order_time_range.length
      ) {
        if (typeof this.order_time_range[0] === "string") {
          this.params.bill_create_time_start =
            new Date(this.order_time_range[0]).getTime() / 1000;
          this.params.bill_create_time_end =
            new Date(this.order_time_range[1]).getTime() / 1000 +
            86400;
        } else {
          this.params.bill_create_time_start =
            this.order_time_range[0].getTime() / 1000;
          this.params.bill_create_time_end =
            this.order_time_range[1].getTime() / 1000 + 86400;
        }
      } else {
        this.params.bill_create_time_start = "";
        this.params.bill_create_time_end = "";
      }
    },
    searchEvent () {
      this.params.page_no = 1;
      this.GET_GoodsAuditList();
    },
    GET_GoodsAuditList () {
      this.handleDateChange();
      let params = {
        ...this.params
      };
      params[this.key_word] = params.keyWord
      delete params.keyWord
      getCustomerAccountVerificationDetailList(params).then(res => {
        this.formData = res;
        this.pageData= { data: [] }
        if(res.detail_page){
          this.pageData = res.detail_page;
        }
      })
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_GoodsAuditList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_GoodsAuditList();
    },
    // 订单导出
    async submitImport (type) {
      let formatList = [];
      if (type === 1) {//导出勾选项
        if (this.tempList.length === 0)
          return this.$message.error("请先选择要导出的数据");
        let snArr = [];
        this.tempList.forEach(item => {
          snArr.push(item.id);
        });
        let order_sn_s = snArr.toString();
        this.importLoading1 = true;
        formatList = await getCustomerAccountVerificationDetailListExportByIds(order_sn_s)
        this.importLoading1 = false;
      } else {//导出全部
        this.importLoading = true;
        formatList = await getCustomerAccountVerificationDetailListExport(this.params)
        this.importLoading = false;
      }
      formatList.forEach(res => {
        res.app_type = this.getAppTypeName(res.app_type)//应用类型
        res.bill_create_time = Foundation.unixToDate(res.bill_create_time)//单据日期
        res.bill_source = res.bill_source == 1 ? '企业后台' : '移动端前台'//单据来源
        res.bill_type = this.bill_type_filters(res.bill_type)//单据类型
      })
      handleDownload(formatList, this.exportHeader, this.exportKeys, "来往对账单据明细列表");
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .button-back{
  height: 40px;
  position: absolute;
  right: 0;
  .back_btn{
    width: 36px;
    height: 36px;
    background-color: #1a43a9;
    border-radius: 50%;
    img{
      height: 20px;
    position: relative;
    top: -2px;
    left: -4px;
    }
  }
}
</style>