import { param } from '@/utils'
import request from '@/utils/request'
import { Header } from 'element-ui'
/**
 * 企业端客户档案列表
 * @param params
 * @returns {Promise<any>}
 */
export function getCustomerList(params) {
  return request({
    url: 'seller/customer/ca',
    method: 'get',
    loading: false,
    params
  })
}
/**
 * 通过客户名称查询是否重复
 */
export function getByNames(name) {
  return request({
    url: `seller/customer/ca/getByName/${name}`,
    method: 'get',
    loading: false
  })
}
/**
 * 添加客户档案
 * @param data
 */
export function addCustomer(data) {
  return request({
    url: 'seller/customer/ca/add',
    method: 'post',
    loading: false,
    headers: {'Content-Type': 'application/json'},
    data: data
  })
}
/**
 * 编辑客户档案
 * @param data
 */
export function editCustomer(data) {
  return request({
    url: `seller/customer/ca/edit/${data.id}`,
    method: 'post',
    headers: {'Content-Type': 'application/json'},
    loading: false,
    data: data
  })
}
/**
 * 查询客户档案账户信息
 * @param data
 */
export function getCustomerAll(id) {
  return request({
    url: `seller/customer/ca/getAll/${id}`,
    method: 'get',
    loading: false
  })
}
/**
 * 查询客户档案详情
 * @param data
 */
export function getCustomerDetail(id) {
  return request({
    url: `seller/customer/ca/${id}`,
    method: 'get',
    loading: false
  })
}
/**
 * 添加客户备注
 * * @param params
 */
export function addCustomerRemark(params) {
  return request({
    url: `seller/customer/ca/${params.id}/remarks`,
    method: 'put',
    loading: false,
    params: {remarks:params.remarks}
  })
}
/**
 * 启用/禁用客户
 * * @param params
 */
export function enableCustomer(id, status) {
  return request({
    url: `seller/customer/ca/${id}/status`,
    method: 'put',
    loading: false,
    params: {status:status}
  })
}

  /**
 * 客户档案导出
 * @param data
 */
export function getCustomerExport(params) {
  return request({
    url: `seller/customer/ca/export`,
    method: 'get',
    loading: false,
    params: params
  })
}
/**
 * 企业端客户账期申请列表
 * @param params
 * @returns {Promise<any>}
 */
export function getCustomerCapaList(params) {
  return request({
    url: 'seller/customer/capa',
    method: 'get',
    loading: false,
    params
  })
}
/**
 * 添加客户账期申请
 * @param data
 */
export function addCustomerCapa(data) {
  return request({
    url: 'seller/customer/capa/add',
    method: 'post',
    loading: false,
    headers: {'Content-Type': 'application/json'},
    data: data
  })
}
/**
 * 查询客户列表下拉框
 * @param id
 */
export function getAllCustomerListByEnterpriseId(id) {
  return request({
    url: `seller/customer/ca/getAllCustomerListByEnterpriseId/${id}`,
    method: 'get',
    loading: false,
  })
}
/**
 * 查询客户账期申请详情
 * @param data
 */
export function getCustomerCapaDetail(id) {
  return request({
    url: `seller/customer/capa/${id}`,
    method: 'get',
    loading: false
  })
}
/**
 * 企业端财务客户账期审批列表
 * @param params
 * @returns {Promise<any>}
 */
export function getCustomerPageList(params) {
  return request({
    url: 'seller/customer/capa/query/page',
    method: 'get',
    loading: false,
    params
  })
}
/**
 * 财务 - 客户账期申请审核财务复审
 * @param {*} params 
 * @returns 
 */
export function customerCapaApproval(data) {
  return request({
    url: `seller/customer/capa/approve/${data.id}`,
    method: 'put',
    headers: {'Content-Type': 'application/json'},
    loading: false,
    params: { apply_status: data.apply_status },
    data:data
  })
} 
/**
 * 财务-定制折扣审批列表
 */
export function getDiscountRateAuditList(params) {
  return request({
    url: 'seller/customer/discountRateAudit/list',
    method: 'get',
    loading: false,
    params
  })
}
/**
 * 财务-定制折扣审批审核操作
 */
export function discountRateAuditAuditing(params) {
  return request({
    url: `seller/customer/discountRateAudit/${params.id}/auth`,
    method: 'put',
    params,
    loading: false
  })
}
/**
 * 财务-客户充值审核-列表
 */
export function getCustomerRechargeAuditList(params) {
  return request({
    url: 'seller/customer/cara',
    method: 'get',
    loading: false,
    params
  })
}
/**
 * 财务-客户充值审核-审核
 */
export function customerRechargeAuditAuditing(id,params) {
  return request({
    url: `seller/customer/cara/audit/${id}`,
    method: 'put',
    loading: false,
    params
  })
}
/**
 * 财务-客户充值审核-详情
 */
export function customerRechargeAuditDetail(id) {
  return request({
    url: `seller/customer/cara/${id}`,
    method: 'get',
    loading: false
  })
}

/**
 * 财务-客户充值-添加
 */
export function addCustomerRecharge(data) {
  return request({
    url: `seller/customer/cara/add`,
    method: 'post',
    loading: false,
    headers: { 'Content-Type': 'application/json' },
    data:data
  })
}
/**
 * 财务-账户人工充值-客户账户明细列表
 */
export function getCustomerAccountDetailList(params) {
  return request({
    url: `seller/customer/cacd`,
    method: 'get',
    loading: false,
    params
  })
}
/**
 * 财务-账户人工充值-客户账户明细列表详情
 */
export function getCustomerAccountDetailDetail(id) {
  return request({
    url: `seller/customer/capclc/${id}`,
    method: 'get',
    loading: false
  })
}
/**
 * 财务-账户人工充值-客户账户明细列表导出
 */
export function getCustomerAccountDetailExport(params) {
  return request({
    url: `seller/customer/cacd/export`,
    method: 'get',
    loading: false,
    params
  })
}
/**
 * 财务-项目结算确认-列表
 */
export function getCustomerDiscountList(params) {
  return request({
    url: `seller/customer/myccp`,
    method: 'get',
    loading: false,
    params
  })
}
/**
 * 财务-项目结算确认-备注
 */
export function getCustomerDiscountRemarks(params) {
  return request({
    url: `seller/customer/myccp/${params.id}/remarks`,
    method: 'put',
    loading: false,
    params
  })
}
/**
 * 财务-项目结算确认-查看核销
 */
export function getModelAndDetail(id) {
  return request({
    url: `seller/customer/myccp/getModelAndDetail/${id}`,
    method: 'get',
    loading: false,
  })
}
/**
 * 财务-项目结算确认-查看核销-核销
 */
export function setCcpsAudit(data) {
  return request({
    url: `seller/customer/ccps/audit/${data.id}`,
    method: 'put',
    data: data,
    headers: { 'Content-Type': 'application/json' },
    loading: false,
  })
}


/**
 * 财务-收款账户管理-列表
 */
export function getEcaList(params) {
  return request({
    url: 'seller/customer/eca',
    method: 'get',
    params: params,
    loading: false,
  })
}
/**
 * 财务-收款账户管理-添加账户
 */
export function addCustomerAccount(data) {
  return request({
    url: 'seller/customer/eca/add',
    method: 'post',
    data: data,
    headers: { 'Content-Type': 'application/json' },
    loading: false,
  })
}
/**
 * 财务-收款账户管理-编辑账户
 */
export function editCustomerAccount(data) {
  return request({
    url: `seller/customer/eca/edit/${data.id}`,
    method: 'post',
    data: data,
    headers: { 'Content-Type': 'application/json' },
    loading: false,
  })
}
/**
 * 财务-收款账户管理-详情
 */
export function getCustomerAccount(id) {
  return request({
    url: `seller/customer/eca/getAll/${id}`,
    method: 'get',
    loading: false,
  })
}

/**
 * 财务-收款账户管理-查询公司名称是否重复
 */
export function getModelByCompanyName(companyName) {
  return request({
    url: `seller/customer/eca/getModelByCompanyName/${companyName}`,
    method: 'get',
    loading: false,
  })
}

/**
 * 财务-收款账户管理-进项明细列表
 */
export function getCustomerAccountIncome(params) {
  return request({
    url: `seller/customer/eigd`,
    method: 'get',
    params: params,
    loading: false,
  })
}
/**
 * 财务-收款账户管理-进项明细列表-所属分类
 */
export function getCustomerAccountIncomeClassification(id) {
  return request({
    url: `seller/customer/eigd/getAllClassification/${id}`,
    method: 'get',
    loading: false,
  })
}
/**
 * 财务-收款账户管理-进项明细列表-添加
 */
export function addCustomerAccountIncome(data) {
  return request({
    url: `seller/customer/eigd/add`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data,
    loading: false,
  })
}
/**
 * 财务-收款账户管理-进项明细列表-编辑
 */
export function editCustomerAccountIncome(data) {
  return request({
    url: `seller/customer/eigd/edit/${data.id}`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data,
    loading: false,
  })
}
/**
 * 财务-收款账户管理-进项明细列表-删除
 */
export function delCustomerAccountIncome(id) {
  return request({
    url: `seller/customer/eigd/del/${id}`,
    method: 'delete',
    loading: false,
  })
}
/**
 * 财务-收款账户管理-进项明细列表-详情
 */
export function getCustomerAccountIncomeDetail(id) {
  return request({
    url: `seller/customer/eigd/${id}`,
    method: 'get',
    loading: false,
  })
}
/**
 * 财务-收款账户管理-进项明细列表-查询货物名称是否重复
 */
export function getCustomerAccountIncomeDetailByName(id,name) {
  return request({
    url: `seller/customer/eigd/getByName/${id}`,
    method: 'get',
    loading: false,
    params:{name}
  })
}
/**
 * 财务-收款账户管理-进项明细列表-导出全部
 */
export function getCustomerAccountIncomeExport(params) {
  return request({
    url: `seller/customer/eigd/export`,
    method: 'get',
    loading: false,
    params
  })
}
/**
 * 财务-收款账户管理-进项明细列表-导入
 */
export function getCustomerAccountIncomeImport (data, id) {
  return request({
    url: `seller/customer/eigd/importIncomeGoodsDetails/${id}`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    loading: false,
    data:data
  })
}
/**
 * 财务-收款账户管理-进项明细列表-导入导出记录列表
 */
export function getCustomerAccountIncomeImportList (params) {
  return request({
    url: `seller/customer/eigdel`,
    method: 'get',
    headers: { 'Content-Type': 'application/json' },
    loading: false,
    params
  })
}
/**
 * 财务-收款账户管理-进项明细列表-导入导出记录列表-下载文件
 */
export function getCustomerAccountIncomeImportExportInfo (id) {
  return request({
    url: `seller/customer/eigdel/incomeGoodsDetailImportExportInfo/${id}`,
    method: 'get',
    headers: { 'Content-Type': 'application/json' },
    loading: false
  })
}
/**
 * 查询折扣模板列表
 */
export function getDiscountRateList(params) {
  return request({
    url: `seller/customer/discountRate/list`,
    method: 'get',
    loading: false,
    params
  })
}
/**
 * 添加折扣模板
 */
export function addDiscountRate(data) {
  return request({
    url: `seller/customer/discountRate/add`,
    method: 'post',
    loading: false,
    data:data
  })
}
/**
 * 修改折扣模板
 */
export function editDiscountRate(data) {
  return request({
    url: `seller/customer/discountRate/edit/${data.id}`,
    method: 'put',
    loading: false,
    data:data
  })
}
/**
 * 通过折扣模板名称查询是否重复
 */
export function checkTemplateName(params) {
  return request({
    url: `seller/customer/discountRate/checkTemplateName`,
    method: 'get',
    loading: false,
    params
  })
}
/**
 * 删除折扣模板
 */
export function delDiscountRate(id) {
  return request({
    url: `seller/customer/discountRate/delete/${id}`,
    method: 'delete',
    loading: false
  })
}
/**
 * 查询折扣模板卡券列表
 */
export function getDiscountCardsList(params) {
  return request({
    url: `seller/customer/discountRate/discountCardsList`,
    method: 'get',
    loading: false,
    params
  })
}
/**
 * 批量添加折扣模板卡券
 */
export function batchAddCards(data) {
  return request({
    url: `seller/customer/discountRate/batchAddCards`,
    method: 'post',
    loading: false,
    headers: { 'Content-Type': 'application/json' },
    data
  })
}
/**
 * 查询客户定制折扣模板卡券列表
 */
export function getCustomizedDiscountCardsList(params) {
  return request({
    url: `seller/customer/discountRate/customizedDiscountCardsList`,
    method: 'get',
    loading: false,
    params
  })
}
/**
 * 添加客户定制折扣模板卡券
 */
export function batchAddCustomizedCards(data) {
  return request({
    url: `seller/customer/discountRate/batchAddCustomizedCards`,
    headers: { 'Content-Type': 'application/json' },
    method: 'post',
    loading: false,
    data
  })
}
/**
 * 客户对账来往核销列表
 */
export function getCustomerAccountVerificationList(params) {
  return request({
    url: `seller/customer/cavrd/listRecord`,
    method: 'get',
    loading: false,
    params
  })
}
/**
 * 客户对账来往核销列表-详情列表
 */
export function getCustomerAccountVerificationDetailList (params) {
  return request({
    url: `seller/customer/cavrd`,
    method: 'get',
    loading: false,
    params
  })
}
/**
 * 客户对账来往核销列表-详情列表-导出全部
 */
export function getCustomerAccountVerificationDetailListExport (params) {
  return request({
    url: `seller/customer/cavrd/exportAll`,
    method: 'get',
    loading: false,
    params
  })
}
/**
 * 客户对账来往核销列表-详情列表-导出选中
 */
export function getCustomerAccountVerificationDetailListExportByIds (ids) {
  return request({
    url: `seller/customer/cavrd/exportByIds/${ids}`,
    method: 'get',
    loading: false,
  })
}
/**
 * 工具-发票信息补录列表
 */
export function getRealityInvoiceList (params) {
  return request({
    url: '/seller/shops/shopReceiptHistory/realityInvoiceList',
    method: 'get',
    loading: false,
    params
  })
}
/**
 * 工具-发票信息补录列表-详情
 */
export function getInvoiceInfo (id) {
  return request({
    url: '/seller/shops/shopReceiptHistory/invoiceInfo',
    method: 'get',
    loading: false,
    params:{record_id:id}
  })
}
/**
 * 工具-发票信息补录列表-详情-销售单明细表格
 */
export function getSellTradeInfo (params) {
  return request({
    url: '/seller/shops/shopReceiptHistory/sellTradeInfo',
    method: 'get',
    loading: false,
    params:params
  })
}
/**
 * 通过企业ID查询所有公司数据
 */
export function getAllCompanyByEnterpriseId (id) {
  return request({
    url: `seller/customer/eca/getAllCompanyByEnterpriseId/${id}`,
    method: 'get',
    loading: false
  })
}
/**
 * 工具-发票信息补录列表-保存真实发票
 */
export function saveRealityInvoice (data) {
  return request({
    url: `/seller/shops/shopReceiptHistory/add/reality`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    loading: false,
    data
  })
}
/**
 * 工具-发票信息补录列表-查看真实发票
 */
export function getRealityInvoiceInfo (id) {
  return request({
    url: `/seller/shops/shopReceiptHistory/invoiceInfo/reality`,
    method: 'get',
    loading: false,
    params:{record_id:id}
  })
}


/**
 * 财务-开卡发票审核列表
 */
export function getCardInvoiceList (params) {
  return request({
    url: `/seller/shops/shopReceiptHistory/getCardInvoiceList`,
    method: 'get',
    params
  })
}
/**
 * 财务-开卡发票审核列表-审核
 */
export function checkCardInvoice (params) {
  return request({
    url: `/seller/shops/shopReceiptHistory/cardInvoice/check`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    loading: false,
    params
  })
}
/**
 * 财务-开卡发票审核列表-邮寄
 */
export function deliveryCardInvoice (record_id,params) {
  return request({
    url: `/seller/shops/shopReceiptHistory/delivery/${record_id}`,
    method: 'put',
    loading: false,
    params:params
  })
}
/**
 * 设置默认收款账户
 */
export function setDefaultInEnterprise (id,status) {
  return request({
    url: `seller/customer/ecad/setDefaultInEnterprise/${id}/${status}`,
    method: 'post',
    loading: false
  })
}
/**
 * 查询充值退款单校验手机号是否在商城存在
 * @param params{shop_id,phone_number}
 */
export function checkoutMember (params) {
  return request({
    url: `seller/trade/recharge/checkoutMember`,
    method: 'get',
    loading: false,
    params
  })
}